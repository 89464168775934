th {
  padding: 5px;
  background-color: #3eaf7c;
  color: white;
}

td {
  padding: 12px 20px;
}

tr:nth-child(even) {
  background: rgba(240, 240, 240, 0.74);
}
tr:nth-child(odd) {
  background: #fff;
}

tr:hover {
  background-color: rgba(62, 175, 124, 0.151);
}

th:first-of-type {
  border-top-left-radius: 6px;
}
th:last-of-type {
  border-top-right-radius: 6px;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 6px;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 6px;
}
